<button style="background-image: url(/../../assets/img/botones/Boton_Aplicar.png);" class="btn btn-finish  btn-wd btn-cabecera" matTooltip="{{'texto_179' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

        <div class="modal-body mx-3">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_401' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 

          <div class="row">
            <div class="col-xs-12 col-md-12">
                <label for="form-name">{{ 'texto_402' | translate }}</label>
                <input type="text" id="nombreAccion" class="form-control" [formControl]="nombreAccion" >
            </div>
            <div class="col-xs-12 col-md-12">
                <label for="form-name">{{ 'texto_403' | translate }}</label>
                <input type="text" id="nombreContacto" class="form-control" [formControl]="nombreContacto" >
            </div>
            <div class="col-xs-12 col-md-12">
                <label for="form-name">{{ 'texto_404' | translate }}</label>
                <input type="email" id="responsable" class="form-control" [formControl]="responsable" >
            </div>
            <div class='col-xs-12 col-sm-12'>
                <label class="control-label" for="textoAccion">{{ 'texto_63' | translate }}</label>
                <ngx-wig id="txtAccion" [formControl]="textoAccion" [buttons]="'bold, italic, link, underline'" (keyup)="onKey($event)"></ngx-wig>
                <span style="float: left;font-weight: bold;font-size:12px">{{contador}} {{ 'texto_69' | translate }} 4000</span>
            </div>
        </div>
            

        <div class="text-center" style="margin-top:-20px;">
          <button (click)="asignarAccion()"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
          </button>
        </div>

    </div>
    <!--/.Content-->
  </div>
</div>