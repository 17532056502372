<button  style="background-image: url(/../../assets/img/botones/Boton_Asignar.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_182' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

        <div class="modal-body mx-3">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_265' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 
          <form #validatingForm="ngForm" class="editForm" novalidate>
            <div class="row">
                <div class='col-xs-12 col-md-12'>
                    <div class="form-group">
                        <label class="control-label" >{{ 'texto_271' | translate }}</label> <br> 
                          <mat-form-field>
                            <mat-label>{{ 'texto_140' | translate }}</mat-label>
                            <mat-select [formControl]="investigador" required>
                              <mat-option *ngFor="let item of investigadores" [value]="item.id_user" >
                                {{item.n_user}} {{item.ape_user}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class='col-xs-6 col-md-6'>
                  <div class="form-group">
                      <label class="control-label" >{{ 'Investigadores ya seleccionados' | translate }}</label> <br> 
                      <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <th colspan="2">{{ 'Investigador' | translate }}</th>
                                <th></th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item2 of inves_select">
                                <td>{{item2.n_user}} {{item2.ape_user}}</td>
                                <td class="td-actions text-right" style="width:90px;">    
                                  <button (click)="deleteinves(item2.id_user)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                    <i class="material-icons">clear</i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
              </div>                
              <div class="col-xs-6 col-md-6" >
                <div *ngIf="cargado===true">
                    <div class="col-xs-12 col-md-12" >
                      <label for="form-name">{{ 'texto_72' | translate }}*</label>
                      <input type="number" [attr.disabled]="cargado" id="plazoFaseInvestigacion" class="form-control" [formControl]="plazoFaseInvestigacion" required [(ngModel)]="plazoFaseInvestigacionF" value="{{plazoFaseInvestigacionF}}">
                    </div>
                    <div class="col-xs-12 col-md-12">
                        <label for="form-name">{{ 'texto_73' | translate }}*</label>
                        <input type="number" [attr.disabled]="cargado" id="plazoFaseDecisor" class="form-control" [formControl]="plazoFaseDecisor" required [(ngModel)]="plazoFaseDecisorF" value="{{plazoFaseDecisorF}}">
                    </div>
                    <div class="col-xs-12 col-md-12">
                        <label for="form-name">{{ 'texto_74' | translate }}*</label>
                        <input type="number" [attr.disabled]="cargado" id="plazoFaseCompliance" class="form-control" [formControl]="plazoFaseCompliance" required [(ngModel)]="plazoFaseComplianceF" value="{{plazoFaseComplianceF}}">
                    </div>
                </div>
                <div *ngIf="cargado!==true">
                    <div class="col-xs-12 col-md-12" >
                      <label for="form-name">{{ 'texto_72' | translate }}*</label>
                      <input type="number" id="plazoFaseInvestigacion" class="form-control" [formControl]="plazoFaseInvestigacion" required [(ngModel)]="plazoFaseInvestigacionF" value="{{plazoFaseInvestigacionF}}">
                    </div>
                    <div class="col-xs-12 col-md-12">
                        <label for="form-name">{{ 'texto_73' | translate }}*</label>
                        <input type="number"  id="plazoFaseDecisor" class="form-control" [formControl]="plazoFaseDecisor" required [(ngModel)]="plazoFaseDecisorF" value="{{plazoFaseDecisorF}}">
                    </div>
                    <div class="col-xs-12 col-md-12">
                        <label for="form-name">{{ 'texto_74' | translate }}*</label>
                        <input type="number"  id="plazoFaseCompliance" class="form-control" [formControl]="plazoFaseCompliance" required [(ngModel)]="plazoFaseComplianceF" value="{{plazoFaseComplianceF}}">
                    </div>
                </div>
                <small style="font-weight: bold;font-size: 10px;">{{ 'texto_400' | translate }}</small>
              </div>
            </div>
              

            <div class="text-center mt-4">
              <button (click)="addInvestigator()"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
              </button>
            </div>
          </form>

    </div>
    <!--/.Content-->
  </div>
</div>