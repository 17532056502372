<button style="background-image: url(/../../assets/img/botones/Boton_Asignar.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_177' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

        <div class="modal-body mx-3">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_406' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 

          <div class="row">       
            <div class="md-form mb-5 col-md-6">
                <mat-form-field>
                    <mat-label>{{ 'texto_407' | translate }}</mat-label>
                    <mat-select  [formControl]="selectDecisor">
                      <mat-option *ngFor="let item of decisores" [value]="item.id_user">
                        {{item.n_user}} {{item.ape_user}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
            
        <div class="text-center mt-4">
          <button (click)="asignarDecisor()" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_408' | translate }}
          </button>
        </div>

    </div>
    <!--/.Content-->
  </div>
</div>