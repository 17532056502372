<button style="background-image: url(/../../assets/img/botones/Boton_Finalizar\ Fase.png);"  class="btn btn-finish btn-wd btn-cabecera" matTooltip="{{'texto_168' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
     <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
        <!--Content-->
        <div class="modal-content">
    
            <div class="modal-body mx-3">
                <div class="card-header card-header-info">
                  <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_167' | translate }}</h5>
                </div>
                <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 
                <p>{{ 'texto_166' | translate }}</p>
                <div class="md-form">
                    <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon> <label class="control-label" for="textoInforme">{{ 'texto_63' | translate }}</label><br>
                    <ngx-wig id="textoInformeInv"  [(ngModel)]="textoInforme" [buttons]="'bold, italic, link, underline'" (keyup)="onKey($event)"></ngx-wig>
                    <span style="float: left;font-weight: bold;font-size:12px">{{contador}} {{ 'texto_69' | translate }} 4000</span>
                </div>         
            </div>
            <div class="text-center" style="margin-top:-20px;">
                <button (click)="enviarInforme()" style="color:#fff" mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
                </button>
              </div>
        </div>
    </div>
</div>