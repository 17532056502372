<button  style="background-image: url(/../../assets/img/botones/Boton_Finalizar\ Fase.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_520' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

        <div class="modal-body mx-3">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_520' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 

          <div class="row">       
            <div class='col-xs-12 col-sm-12'>
                <mat-checkbox id="enviarDenunciaReceptor" name="enviarDenunciaReceptor" [formControl]="enviarDenunciaReceptor">{{ 'texto_521' | translate }}</mat-checkbox>
            </div>
        </div>
            

        <div class="text-center mt-4">
          <button (click)="validar()" style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_41' | translate}}
          </button>
        </div>

    </div>
    <!--/.Content-->
  </div>
</div>